






























































import { Component, Vue } from "vue-property-decorator";
@Component({
	name: "findPassowrd"
})
export default class FindPassowrd extends Vue {
	$refs: { [key: string]: any } = {
		phoneForm: HTMLInputElement,
		passwordForm: HTMLInputElement
	};
	private formData = {
		phone: "",
		password: "",
		code: "",
		agreement: false
	};
	private active = 0;

	private onFocus(name: string, active: number) {
		this.$nextTick(() => {
			console.log(this.$refs);
			this.$refs[name].focus();
			this.active = active;
		});
	}
}
